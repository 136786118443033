const choo = require('choo');
const download = require('./ui/download');
const body = require('./ui/body');
const { hexToBase64, md5 } = require('./shortLink');

module.exports = function(app = choo({ hash: true })) {
  app.route('/', body(require('./ui/home')));
  app.route('/download/:id', body(download));
  app.route('/download/:id/:key', body(download));
  app.route('/unsupported/:reason', body(require('./ui/unsupported')));
  app.route('/make_public/:sid', async function(state, emit) {
    const sid = state.params.sid;
    try {
      let res = await fetch(`/api/s/${sid}`);
      if (res.status !== 200) {
        emit('pushState', '/404');
        return;
      }
      let id = await res.json();
      id = id.id;
      const secret = hexToBase64(md5(sid))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
      emit('jumpToUploadComplete', {
        id: id,
        secretKey: secret,
        shortLink: sid
      });
    } catch (e) {
      emit('pushState', '/404');
    }
  });
  app.route('/error', body(require('./ui/error')));
  app.route('/blank', body(require('./ui/blank')));
  app.route('/oauth', function(state, emit) {
    emit('authenticate', state.query.code, state.query.state);
  });
  app.route('/login', function(state, emit) {
    emit('replaceState', '/');
    setTimeout(() => emit('render'));
  });
  app.route('*', body(require('./ui/notFound')));
  return app;
};
