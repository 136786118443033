const html = require('choo/html');

module.exports = function intro(state) {
  return html`
    <send-intro
      class="block flex flex-col bg-white h-full w-full dark:bg-grey-90 pt-4"
    >
      <div class="order-2 md:order-1 pt-16 pl-4 flex flex-col h-1/2">
        <h1 class="text-3xl font-bold md:pb-2">
          ${state.translate('introTitle')}
        </h1>
        <p class="max-w-sm leading-loose mt-6 md:mt-2 md:pr-14">
          ${state.translate('introDescription')}
        </p>
      </div>
      ${get_token()
        ? html`
            <div
              class="order-1 md:order-2 flex flex-col items-center justify-center border-2 border-dashed border-grey-transparent rounded px-6 py-8 md:py-6 flex-1 w-full dark:border-grey-60 h-1/2"
            >
              <div
                class="pt-6 pb-2 text-center text-lg font-bold tracking-wide"
              >
                ${state.translate('makePublic')}
              </div>
              <input
                id="make-public-input"
                class="mx-auto text-center border rounded focus:border-blue-60 leading-normal my-1 py-1 px-2 h-8 dark:bg-grey-80"
                type="text"
                placeholder="${state.translate('enterShortLink')}"
                oninput="${enterShortLink}"
              />
              <button
                disabled
                id="make-public-btn"
                class="btn rounded-lg flex items-center mt-4"
                title="${state.translate('okButton')}"
                onclick="${jumpToMakePublic}"
              >
                ${state.translate('okButton')}
              </button>
            </div>
          `
        : ''}
    </send-intro>
  `;

  function get_token() {
    const cookies = document.cookie.split(';').map(function(x) {
      return x.trim();
    });
    for (let cookie of cookies) {
      if (cookie.startsWith('token=')) return cookie.substring(6);
    }
    return undefined;
  }

  function enterShortLink(event) {
    event.preventDefault();
    const newLink = event.target.value;
    const btn = document.getElementById('make-public-btn');
    btn.disabled = newLink.length !== 6;
  }

  function jumpToMakePublic(event) {
    event.preventDefault();
    const code = document.getElementById('make-public-input').value;
    window.location.pathname = `/api/make_public/${code}`;
  }
};
