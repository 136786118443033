function readEntries(reader) {
  return new Promise((resolve, reject) => {
    reader.readEntries(resolve, reject);
  });
}

function toFile(file) {
  return new Promise((resolve, reject) => {
    file.file(file_from_fs => {
      Object.defineProperty(file_from_fs, 'webkitRelativePath', {
        get: () => file.fullPath.substring(1)
      });
      resolve(file_from_fs);
    }, reject);
  });
}

async function readDirectory(directory) {
  if (directory.isFile) {
    return [directory];
  }
  let dirReader = directory.createReader();
  let entries = await readEntries(dirReader);
  let dirs = entries.filter(e => e.isDirectory);
  let files = entries.filter(e => e.isFile);
  if (dirs.length === 0) {
    return files;
  }
  let files_in_subdirectories = await Promise.all(dirs.map(readDirectory));
  return files.concat(
    files_in_subdirectories.reduce((a, b) => a.concat(b), [])
  );
}

export default function(state, emitter) {
  emitter.on('DOMContentLoaded', () => {
    document.body.addEventListener('dragover', event => {
      if (state.route === '/') {
        event.preventDefault();
      }
    });
    document.body.addEventListener('drop', event => {
      if (
        state.route === '/' &&
        !state.uploading &&
        event.dataTransfer &&
        event.dataTransfer.items
      ) {
        event.preventDefault();
        for (let item of event.dataTransfer.items) {
          readDirectory(item.webkitGetAsEntry()).then(files => {
            Promise.all(files.map(toFile)).then(files => {
              emitter.emit('addFiles', {
                files
              });
            });
          });
        }
      }
    });
  });
}
