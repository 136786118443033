function getToken() {
  const cookies = document.cookie.split(';').map(function(x) {
    return x.trim();
  });
  for (let cookie of cookies) {
    if (cookie.startsWith('token=')) return cookie.substring(6);
  }
}

function getContext(locale, url, archive) {
  const hasToken = getToken();
  if (archive.shortLink) {
    if (locale === 'zh-CN') {
      if (hasToken) {
        return `传输链接: (内网) ${url} (公网) ${url.replace(
          'send.',
          'send-wan.'
        )} 或打开 send.deemos.com / send-wan.deemos.com 使用传输口令: ${
          archive.shortLink
        } 提取.`;
      } else {
        return `传输链接: (内网) ${url} 或打开 send.deemos.com 使用传输口令: ${archive.shortLink} 提取.`;
      }
    } else {
      if (hasToken) {
        return `transfer link: (LAN) ${url} (WAN) ${url.replace(
          'send.',
          'send-wan.'
        )} or access [ send.deemos.com / send-wan.deemos.com ] and input extract code: ${
          archive.shortLink
        } to download.`;
      } else {
        return `transfer link: (LAN) ${url} or access [ send.deemos.com ] and input extract code: ${archive.shortLink} to download.`;
      }
    }
  }

  if (locale === 'zh-CN') {
    if (hasToken) {
      return `传输链接: (内网) ${url} (公网) ${url.replace(
        'send.',
        'send-wan.'
      )}.`;
    } else {
      return `传输链接: (内网) ${url}.`;
    }
  } else {
    if (hasToken) {
      return `transfer link: (LAN) ${url} (WAN) ${url.replace(
        'send.',
        'send-wan.'
      )}.`;
    } else {
      return `transfer link: (LAN) ${url}.`;
    }
  }
}

module.exports = { getToken, getContext };
