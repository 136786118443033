const html = require('choo/html');
const { copyToClipboard } = require('../utils');
const qr = require('./qr');
const { getContext } = require('./secretToken');

module.exports = function(name, url, shortLink) {
  const dialog = function(state, emit, close) {
    return html`
      <send-copy-dialog
        class="flex flex-col items-center text-center p-4 max-w-sm m-auto"
      >
        <h1 class="text-3xl font-bold my-4">
          ${state.translate('notifyUploadEncryptDone')}
        </h1>
        <p
          class="font-normal leading-normal text-grey-80 word-break-all dark:text-grey-40"
        >
          ${state.translate('copyLinkDescription')} <br />
          ${name}
        </p>
        <div class="flex flex-row items-center justify-center w-full">
          <input
            type="text"
            id="share-url"
            class="block w-full text-center my-4 border rounded-lg leading-loose h-12 px-2 py-1 dark:bg-grey-80 hover:border-blue"
            value="${url}"
            onclick=${copyUrl}
            readonly
          />
          <div
            class="${shortLink
              ? 'block'
              : 'hidden'} relative ml-2 mr-1 my-4 h-12 px-2 py-1 leading-loose z-0"
            id="short-link-url"
          >
            ${shortLink}
            <input
              type="text"
              class="${shortLink
                ? 'block'
                : 'hidden'} absolute top-0 left-0 text-center w-full h-full border rounded-lg leading-loose z-10 dark:bg-grey-80 hover:border-blue"
              value="${shortLink}"
              onclick=${copyShortLink}
              readonly
            />
          </div>
          <button
            id="qr-btn"
            class="w-16 m-1 p-1"
            onclick="${toggleQR}"
            title="QR code"
          >
            ${qr(url)}
          </button>
        </div>
        <button
          class="btn rounded-lg w-full flex-shrink-0 focus:outline"
          onclick="${copy}"
          title="${state.translate('copyLinkButton')}"
        >
          ${state.translate('copyLinkButton')}
        </button>
        <button
          class="link-blue my-4 font-medium cursor-pointer focus:outline"
          onclick="${close}"
          title="${state.translate('okButton')}"
        >
          ${state.translate('okButton')}
        </button>
      </send-copy-dialog>
    `;

    function toggleQR(event) {
      event.stopPropagation();
      const shareUrl = document.getElementById('share-url');
      const shortUrl = document.getElementById('short-link-url');
      const qrBtn = document.getElementById('qr-btn');
      if (shareUrl.classList.contains('hidden')) {
        shareUrl.classList.replace('hidden', 'block');
        qrBtn.classList.replace('w-48', 'w-16');
        if (state.archive.shortLink) {
          shortUrl.classList.replace('hidden', 'block');
        }
      } else {
        shareUrl.classList.replace('block', 'hidden');
        qrBtn.classList.replace('w-16', 'w-48');
        if (state.archive.shortLink) {
          shortUrl.classList.replace('block', 'hidden');
        }
      }
    }

    function copy(event) {
      event.stopPropagation();
      const content = getContext(state.locale, url, state.archive);
      copyToClipboard(content);
      const oldContent = event.target.textContent;
      event.target.textContent = state.translate('copiedUrl');
      setTimeout(() => {
        event.target.value = oldContent;
      }, 1000);
    }

    function copyUrl(event) {
      event.stopPropagation();
      copyToClipboard(url);
      const oldContent = event.target.value;
      event.target.value = state.translate('copiedUrl');
      setTimeout(() => {
        event.target.value = oldContent;
      }, 1000);
    }

    function copyShortLink(event) {
      event.stopPropagation();
      copyToClipboard(state.archive.shortLink);
      const oldContent = event.target.value;
      event.target.value = state.translate('copiedUrl');
      setTimeout(() => {
        event.target.value = oldContent;
      }, 1000);
    }
  };
  dialog.type = 'copy';
  return dialog;
};
